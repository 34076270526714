import * as React from 'react'

import { margin, spacing, styled } from '@thg-commerce/gravity-theme'
import { Margin } from '@thg-commerce/gravity-theme/margin'

export interface BackgroundContainerProps {
  children: React.ReactNode
  backgroundColour?: string
  margin?: Margin
}

const BackgroundContainer = styled.div<{
  backgroundColour?: string
  margin?: Margin
}>`
  ${(props) =>
    props.backgroundColour &&
    `
      background-color: ${props.backgroundColour};
      padding: ${spacing(2)} 0;
      margin: ${spacing(2)} 0;
      max-width: 100%;
  `}
  ${(props) => props.margin && margin(props.margin)}
`

export const WidgetBackgroundColour = (props: BackgroundContainerProps) => {
  return (
    <BackgroundContainer
      backgroundColour={props.backgroundColour}
      margin={props.margin}
    >
      {props.children}
    </BackgroundContainer>
  )
}
