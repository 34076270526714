import { HorizontalAlignment } from '@thg-commerce/enterprise-core'
import { mq, spacing, styled, Text } from '@thg-commerce/enterprise-theme'
import { mediaQueryRenderer } from '@thg-commerce/gravity-theme'

import { GapInterface, ItemAlignmentType } from '../theme'

export const TitleContainer = styled.div<{
  horizontalAlignment: HorizontalAlignment
}>`
  width: 100%;
  margin-bottom: ${spacing(3)};
  text-align: ${(props) => props.horizontalAlignment};
`

export const Title = styled.h2<{ textColour?: string }>`
  ${(props) =>
    Text(
      props.theme.widget.categorySet.title.font.entry,
      props.theme.widget.categorySet.title.font.style,
      props.theme.widget.categorySet.title.font.hasScaledText,
    )};
  margin-bottom: ${spacing(1)};
  word-break: break-word;
  ${(props) =>
    props.textColour &&
    `color: ${
      props.textColour === 'light'
        ? props.theme.widget.categorySet.multiCategoryCardSetV2.titleColor
        : props.theme.colors.palette.greys.darker
    }`}
`

export const Subtitle = styled.p<{ textColour?: string }>`
  ${Text('bodyText', 'default')};
  ${(props) =>
    props.textColour &&
    `color: ${
      props.textColour === 'light'
        ? props.theme.colors.palette.greys.light
        : props.theme.colors.palette.greys.darker
    }`}
`

export const CategoryContainer = styled.div<{
  horizontalAlignment: HorizontalAlignment
  containerGap: GapInterface
}>`
  display: flex;
  flex-direction: row;
  ${(props) =>
    props.horizontalAlignment === 'left'
      ? `justify-content: flex-start; text-align: left;`
      : props.horizontalAlignment === 'right'
      ? `justify-content: flex-end; text-align: right;`
      : `justify-content: center; text-align: center;`};
  flex-wrap: wrap;

  ${(props) =>
    mediaQueryRenderer(
      props.containerGap.column,
      (size) => `
          column-gap: ${spacing(size)};
        `,
    )}
  ${(props) =>
    mediaQueryRenderer(
      props.containerGap.row,
      (size) => `
          row-gap: ${spacing(size)};
        `,
    )}
`

export const CategoryItem = styled.div<{
  horizontalAlignment?: HorizontalAlignment
  itemAlignment: ItemAlignmentType
  numberOfColumns?: number
  mobileColumns?: number
  styleOverride?: boolean
}>`
  display: flex;
  flex-direction: column;
  flex: ${(props) =>
    props.styleOverride
      ? `0 0 calc(50% - ${spacing(1)});`
      : `0 0 calc(50% - ${spacing(2)});`};
  text-align: ${(props) => props.horizontalAlignment};
  ${(props) =>
    props.horizontalAlignment === 'left'
      ? `align-items: flex-start;`
      : props.horizontalAlignment === 'right'
      ? `align-items: flex-end;`
      : props.itemAlignment === 'initial'
      ? `align-items: initial;`
      : `align-items: center`};

  ${(props) => mq(props.theme.breakpointUtils.map, 'xs')} {
    ${(props) =>
      props.mobileColumns
        ? `flex: 0 0 calc(${100 / props.mobileColumns}% - ${spacing(4)});`
        : props.styleOverride
        ? `flex 0 0 calc(50% - ${spacing(1)});`
        : `flex 0 0 calc(50% - ${spacing(4)});`}
  }

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    ${(props) =>
      props.numberOfColumns && props.numberOfColumns > 2
        ? `flex: 0 0 calc(33% - ${spacing(4)});`
        : `flex 0 0 calc(50% - ${spacing(4)});`}
  }

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    ${(props) =>
      props.numberOfColumns
        ? `flex: 0 0 calc(${100 / props.numberOfColumns}% - ${spacing(4)});`
        : `flex 0 0 calc(33% - ${spacing(4)});`}
  }
`
