import * as React from 'react'

import { HorizontalAlignment } from '@thg-commerce/enterprise-core'
import {
  EditorialItem,
  EditorialItemProps,
} from '@thg-commerce/enterprise-widget-editorial'
import {
  MarginInterface,
  PaddingInterface,
} from '@thg-commerce/enterprise-widget-editorial/theme'
import { ButtonEmphases } from '@thg-commerce/gravity-elements'
import { TextStyling } from '@thg-commerce/gravity-patterns'
import { SpacingBox } from '@thg-commerce/gravity-theme'

import {
  CategoryContainer,
  CategoryItem,
  Subtitle,
  Title,
  TitleContainer,
} from './styles'
import { ContainerInterface } from '../theme'

export type CategorySetItemProps = EditorialItemProps & {
  horizontalAlignment: HorizontalAlignment
  emphasis?: ButtonEmphases
  ctaPadding?: SpacingBox
  anchorWidth?: string
}

export interface CategorySetProps {
  titleProps?: {
    title: string
    titleColour?: 'light' | 'dark'
  }
  subtitleProps?: {
    subtitle: string
    subtitleColour?: 'light' | 'dark'
  }
  horizontalAlignment?: HorizontalAlignment
  items: CategorySetItemProps[]
  className?: string
  gutterPadding: PaddingInterface
  container: ContainerInterface
  gutterMargin: MarginInterface
  numberOfColumns?: number
  mobileColumns?: number
  font?: TextStyling
  styleOverride?: boolean
  anchorWidth?: string
}

export const CategorySetItem = (props: CategorySetItemProps) => {
  return <EditorialItem {...props} />
}

export const CategorySet = (props: CategorySetProps) => {
  const categoryItemsMap = props.items.map((item, index) => {
    return (
      <CategoryItem
        key={index}
        horizontalAlignment={item.horizontalAlignment || 'center'}
        itemAlignment={props.container.itemAlignment}
        data-testid="category-item-container"
        numberOfColumns={props.numberOfColumns}
        mobileColumns={props.mobileColumns}
        styleOverride={props.styleOverride}
      >
        <CategorySetItem
          font={item.font}
          picture={item.picture}
          onHoverSrc={item.onHoverSrc}
          buttonContent={item.buttonContent}
          buttonLink={item.buttonLink}
          titleProps={item.titleProps}
          content={item.content}
          highlightProps={item.highlightProps}
          key={index}
          horizontalAlignment={item.horizontalAlignment || 'center'}
          contentGutterPadding={item.contentGutterPadding}
          emphasis={item.emphasis || 'low'}
          ctaPadding={item.ctaPadding}
          anchorWidth={item.anchorWidth}
        />
      </CategoryItem>
    )
  })

  return (
    <div className={props.className}>
      {(props.titleProps || props.subtitleProps) && (
        <TitleContainer
          horizontalAlignment={props.horizontalAlignment || 'center'}
          data-testid="category-set-title-container"
        >
          {props.titleProps && (
            <Title textColour={props.titleProps.titleColour}>
              {props.titleProps.title}
            </Title>
          )}
          {props.subtitleProps && (
            <Subtitle textColour={props.subtitleProps.subtitleColour}>
              {props.subtitleProps.subtitle}
            </Subtitle>
          )}
        </TitleContainer>
      )}
      <CategoryContainer
        horizontalAlignment={props.horizontalAlignment || 'center'}
        data-testid="category-set-items-container"
        containerGap={props.container.gap}
      >
        {categoryItemsMap}
      </CategoryContainer>
    </div>
  )
}
