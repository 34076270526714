import * as React from 'react'

import { HorizontalAlignment } from '@thg-commerce/enterprise-core'
import {
  CategoryItemCard,
  GravityPicture,
} from '@thg-commerce/enterprise-network/src/generated/graphql'

import {
  CategorySetItem,
  CategorySetItemProps,
} from '@thg-commerce/enterprise-widget-category-set'

import { BaseWidgetProps } from '../types'

export type CategorySetItemWidgetRendererProps = BaseWidgetProps &
  Omit<CategoryItemCard, 'id' | 'query'> & { picture: GravityPicture }

export const CategorySetItemWidgetRenderer = (
  props: CategorySetItemWidgetRendererProps,
) => {
  const categorySetItemProps: CategorySetItemProps = {
    picture: props.picture,
    buttonContent: (props.itemCTA as string) || '',
    buttonLink: (props.itemUrl as string) || '',
    horizontalAlignment: props.textAlignment as HorizontalAlignment,
  }
  return <CategorySetItem {...categorySetItemProps} />
}
